import type { GlideAuth } from '@uc/transaction-core.react/dist/services/api';
import axios from 'axios';
import { REFRESH_WEBAPP_TOKEN_URL, LOGOUT_URL } from 'src/api/urls';
import type { RefreshWebappTokenResponse } from 'src/types/proto/services/auth_public_service';

const PREFIX = 'glide';
export const TIME_BEFORE_EXPIRE = 300;
export const TOKEN_REMEMBER = `${PREFIX}_auth_remember`;
export const AUTH_TOKEN = `${PREFIX}_auth_token`;
export const DEFAULT_TOKEN = '';

export type JwtPayload = {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  scopes: string[];
  sub: string;
};

function setIsRemember(remember?: boolean) {
  localStorage.setItem(TOKEN_REMEMBER, remember ? 'true' : 'false');
}

function getIsRemember(): boolean {
  return localStorage.getItem(TOKEN_REMEMBER) === 'true';
}

async function logout() {
  try {
    const result = await axios.request({
      url: LOGOUT_URL,
      method: 'post',
      baseURL: '/api/',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return !!result;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function setToken(token: string, remember?: boolean) {
  if (remember) {
    localStorage.setItem(AUTH_TOKEN, token);
  } else {
    sessionStorage.setItem(AUTH_TOKEN, token);
  }
  setIsRemember(remember);
}

export function removeToken(): void {
  localStorage.removeItem(AUTH_TOKEN);
  sessionStorage.removeItem(AUTH_TOKEN);
}

export async function getToken(): Promise<string> {
  const authToken =
    window.Glide?.authBearer ||
    localStorage.getItem(AUTH_TOKEN) ||
    sessionStorage.getItem(AUTH_TOKEN);

  if (authToken) {
    const exp = parseJwtPayload(authToken)?.exp;
    const now = getUnixNowTime();

    if (exp && now + TIME_BEFORE_EXPIRE < exp) {
      return authToken;
    }
    if (exp && now + TIME_BEFORE_EXPIRE >= exp && now < exp) {
      try {
        const res = await axios.request<{ data: RefreshWebappTokenResponse }>({
          url: REFRESH_WEBAPP_TOKEN_URL,
          method: 'post',
          baseURL: '/api/',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });
        const { token } = res?.data?.data || {};
        const isRemember = getIsRemember();
        if (token) {
          setToken(token, isRemember);
          return token;
        }
        const result = await logout();
        if (result) {
          removeToken();
        }
      } catch (error) {
        console.error(error);
        return DEFAULT_TOKEN;
      }
    } else {
      removeToken();
    }
  } else {
    await logout();
  }

  return DEFAULT_TOKEN;
}

export async function getTokenType(): Promise<GlideAuth> {
  if (window.Glide?.IS_FE_BE_SPLIT) {
    const token = await getToken();
    return {
      token,
      type: 'jwt',
    };
  }
  return {
    token: window.csrfToken || '',
    type: 'csrf',
  };
}

export function parseJwtPayload(token: string): JwtPayload | null {
  if (!token || token === DEFAULT_TOKEN) {
    return null;
  }

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getUnixNowTime(): number {
  return Math.round(new Date().getTime() / 1000);
}
